import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy policy" />

    <main>
      <article className="o-container o-container--small">
        <h1 className="u-mb u-mt-30">How to play romanian whist</h1>

        <p className="u-mb">
          Romanian whist is a bid & trick-taking card game where each player
          tries to win exactly as many tricks as they have bid.
        </p>

        <h2 className="u-mb u-mt-20">Players</h2>
        <p className="u-mb">3-6 players</p>

        <h2 className="u-mb u-mt-20">Cards</h2>
        <p className="u-mb">
          8 cards for each player, starting from the highest card rank (24 cards
          for 3 players, 32 for 4 players, etc.)
        </p>
        <p className="u-mb">Card ranks: A, K, Q, J, 10, 9...</p>

        <h2 className="u-mb u-mt-20">Deal</h2>
        <p className="u-mb">
          The number of cards dealt to each player varies during the game.
        </p>
        <p className="u-mb">
          E.g. for 3 players: 1, 1, 1, 2, 3, 4, 5, 6, 7, 8, 8, 8, 7, 6, 5, 4, 3,
          2, 1, 1, 1
        </p>
        <p className="u-mb">
          E.g. for 4 players: 1, 1, 1, 1, 2, 3, 4, 5, 6, 7, 8, 8, 8, 8, 7, 6, 5,
          4, 3, 2, 1, 1, 1, 1
        </p>
        <p className="u-mb">
          The rounds with 1 and 8 cards is equal to the number of player.
        </p>
        <p className="u-mb">
          For rounds with 1 to 7 cards, there is also a TRUMP card. Rounds with
          8 cards will be played without a TRUMP.
        </p>

        <h2 className="u-mb u-mt-20">Bidding</h2>
        <p className="u-mb">
          Every player in order, says how many tricks he thinks he will get.
        </p>
        <p className="u-mb">
          The sum of all tricks bid must not be the same as the number of cards
          dealt to each player.{" "}
        </p>
        <p className="u-mb">
          E.g. round with four cards, three players: the first player says "2",
          the next "1". The third player can not bid "1", because that would
          make the sum of the tricks 4. He can bid 0, 2, 3 or 4.
        </p>

        <h2 className="u-mb u-mt-20">Play</h2>
        <p className="u-mb">
          The current round first player plays the first card. The other players
          must play a card of the same suit if possible. Any player who has no
          card of the suit led must play a trump if they can. A player who has
          no cards of the suit led and no trumps can play any card. The trick is
          won by whoever played the highest trump, or if no trump was played, by
          whoever played the highest card of the suit led. The winner of the
          trick leads to the next. The objective is to win exactly the number of
          tricks you said you would win.
        </p>

        <h2 className="u-mb u-mt-20">Turn</h2>
        <p className="u-mb">
          At the beginning of every round the starting player changes clockwise,
          taking in consideration the previous rounds starting player. During a
          round, after the first trick, the player who won the last trick will
          play the first card.
        </p>

        <h2 className="u-mb u-mt-20">Scoring</h2>
        <p className="u-mb">A round ends when all cards are played.</p>
        <ul className="u-pl u-mb">
          <li>
            The players who made their contract (exactly) get 5 points plus the
            number of tricks they made
          </li>
          <li>
            If you take fewer tricks than you bid you lose one point for each
            undertrick
          </li>
          <li>
            If you take more tricks than you bid you lose one point for each
            overtrick
          </li>
        </ul>
        <p className="u-mb">
          E.g. Suppose you bid 3 tricks. If you take exactly 3 you will win 8
          points (5+3). If you take only two tricks you lose 1 point; the same
          if you take 4 tricks. If you take 1 or 5 tricks (two different from
          your bid) you will lose 2 points; if you take no tricks or 6 tricks
          you will lose 3.
        </p>

        <h2 className="u-mb u-mt-20">Bonus points</h2>
        <ul className="u-pl u-mb">
          <li>5 consecutive wins - plus 5 points</li>
          <li>5 consecutive losses - minus 5 points</li>
        </ul>
      </article>
    </main>
  </Layout>
);

export default PrivacyPage;
